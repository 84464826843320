import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import { AppleStore, Logo, PlayStore } from "../Assets";
import { Nav } from "../Constant";
import "./Style.css";
import {
  HeroBgImg,
  HeroImage,
  Logo,
  PartnerImageA,
  PartnerImageB,
  PartnerImageC,
  PartnerImageD,
  PartnerImageE,
  PartnerImageF,
  PartnerImageG,
} from "../Assets";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "#about" },
  { name: "Contact Us", href: "#contact" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <Nav />
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">LogicGraph</span>
                <img className="h-8 w-auto" src={Logo} alt="Logo" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Sign Up
                  </a>
                </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      {/*  */}
      <div className="relative">
        <img
          src={HeroBgImg}
          className="absolute inset-0 object-center w-full h-full"
          alt=""
        />
        <div className="relative bg-mainColorShade" id="header">
          <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-[400px]">
            <div className="flex flex-col items-center justify-center xl:flex-row">
              <div
                className="w-full max-w-7xl mb-12"
                id="headding"
              >
                <h2 className="max-w-7xl mb-6 font-sans text-4xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none text-center">
                  Leverage artificial intelligence (AI) to accelerate the growth
                  of your business.
                </h2>
                <p className="max-w-7xl mb-4 text-xl font-normal text-white md:text-lg text-center">
                  Experience the power of LogicGraph's leading-edge AI
                  technology to transform your business with tailored solutions
                  that increase efficiency and profitability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Infinite Scrolling */}
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img src={PartnerImageA} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageB} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageC} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageD} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageE} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageF} alt="" />
          </div>
          <div class="slide">
            <img src={PartnerImageG} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
