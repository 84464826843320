import React, { useState } from "react";
import "../UI/Style.css";


export default function CookieBanner() {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setAcceptedCookies(true);
  };

  const isCookiesAccepted = localStorage.getItem("cookiesAccepted");

  if (isCookiesAccepted || acceptedCookies) {
    return null;
  }

  return (
    <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 flex justify-between items-center">
      <p>
        This website uses cookies. By using this site, you agree to our use of
        cookies.
      </p>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={acceptCookies}
      >
        Accept
      </button>
    </div>
  );
}
