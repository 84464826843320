import React from "react";
import { Body, Footer, Header, Hero } from "../UI";
import Cookie from "../Constant/Cookies";

export default function Home() {
  return (
    <div>
      <Header />
      <Hero />
      <Body />
      <div className="bg-mainColor">
        <Cookie />
        <Footer />
      </div>
    </div>
  );
}
