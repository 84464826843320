import React from "react";
import { HeroImg } from "../Assets";

export default function Hero() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex items-center justify-center ">
          <div>
            <img
              className="object-center w-full h-56 sm:h-96 bg-none"
              src={HeroImg}
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <div className="max-w-2xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none">
              Where We Stand
            </h2>
            <h1 className="text-4xl font-bold text-gray-700">
              At the core of our strategy lies the integration of AI and
              sustainability principles.
            </h1>
          </div>
          <div className="max-w-2xl text-xl font-semibold text-gray-800">
            <h1>
              LogicGraph is the undisputed industry leader, committed to helping
              African agribusinesses achieve unparalleled success through our
              unwavering commitment to excellence.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
